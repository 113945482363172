import React, { useEffect, useRef } from 'react';
import './Communicatin.css';
import 'animate.css';

const Communication = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  const titleRef = useRef(null);

  useEffect(() => {
    const titleElement = titleRef.current;
    const handleScroll = () => {
      // Check if the title element is in view
      const titleTop = titleElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (titleTop < windowHeight) {
        // If the title is in view, add the animation class
        titleElement.classList.add('animate-typing');
        // Remove the scroll event listener to prevent multiple triggers
        window.removeEventListener('scroll', handleScroll);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className='Communication'>
      <img className='ComSlider' src='/src/autre/comSlider.png' alt='loremedia' />

      <div className='ComSection'>
        <div className='Comdiv1'>
          <p className='full_com_text'>
            <h2 style={{ color: "#1C1A50" }} className="animate__animated animate__bounceInLeft comTitle my-6 text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl">
              COMPRENDRE VOS ENVIES
            </h2>
            <div className='animate__animated animate__bounceInLeft theGreenLine'></div>

            <p className='animate__animated animate__bounceInLeft com_paragraph_1'>
              Vous créez votre entreprise et vous n'avez aucun support de communication?
              Vous souhaitez moderniser votre univers graphique? Vous désirez une meilleure visibilité de
              votre entreprise sur internet ?
            </p>

            <h3 className='animate__animated animate__bounceInLeft mt-2 com_paragraph_2'>
              NOUS AVONS LA RÉPONSE ET DES SOLUTIONS ADAPTÉES POUR TOUTES VOS PROBLÉMATIQUES.
            </h3>

            <p className='animate__animated animate__bounceInLeft com_paragraph_3 mt-2'>
              Notre équipe de professionnels est à votre disposition pour vous apporter les meilleurs conseils.
            </p>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
              Pour cela nous devons comprendre parfaitement votre projet et votre structure: ses atouts, ses faiblesses et ses objectifs.
            </p>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
              Notre priorité est d'échanger avec vous afin de vous proposer des solutions sur mesure qui répondront à vos besoins, vos attentes et vos envies.
              <br />
              <span className='animate__animated animate__bounceInLeft com_paragraph_5_green'>
                Nous prenons soin de proposer des réponses en accord avec vos valeurs et l'image de votre entreprise
              </span>
            </p>

            <h2 style={{ color: "#1C1A50" }} className="animate__animated animate__bounceInLeft comTitle my-6 text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl">
              NOTRE PHILOSOPHIE
            </h2>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-1'>
              Mieux vous connaître, c'est mieux comprendre vos besoins et vos envies. Alors, échangeons lors d'un rendez-vous!
            </p>
          </p>
        </div>
        <img className="animate__animated animate__bounceInRight imgSection" src="https://img1.wsimg.com/isteam/ip/d051f0a2-e55a-4812-a789-3ce4844c9c71/Design.jpg" alt="" />
      </div>

      <div className="flex items-center justify-center">
        <div className="w-max">
          <h1
            ref={titleRef}
            style={{ color: "#1C1A50" }}
            className="SecondTitle overflow-hidden whitespace-nowrap border-r-2 border-r-slate-700 pr-2 text-5xl font-bold">
            UNE ÉQUIPE QUI SAIT ÉCOUTER
          </h1>
        </div>
      </div>



    </div>
  );
}

export default Communication;
