import React, { useState, useEffect } from 'react';
import app from '../../firebase/firebase';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom'

import './Admin.css'

const Admin = () => {

    useEffect(()=>{
        const mySession = localStorage.getItem("sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)");
        if(mySession === 'true'){
            navigate("/anaadmin/dashboard");
        }
        else{
            
        }
    })


    const [loginData, setLoginData] = useState([]);

    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getFirestore(app);
                const loginRef = collection(db, "admin");
                const querySnapshot = await getDocs(loginRef);

                const adminData = querySnapshot.docs.map(doc => ({
                    username: doc.data().username,
                    password: doc.data().password
                }));
                setLoginData(adminData);
            } catch (error) {
                console.error("error", error);
            }
        };

        fetchData();

    }, []);



    const Sign_In_To_Dashboard = () => {
        var email = document.getElementById("email").value;
        var password = document.getElementById("password").value;

        const foundUser = loginData.find(user => user.username === email && user.password === password);

        if (foundUser) {
            localStorage.setItem("sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)", true);

            navigate("/anaadmin/dashboard");
        } else {
            alert("User not found!");
        }
    }



    return (
        <div>

            <section className="loginSection">
                <div className="loginSectionForm flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <Link to={"/"} className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img className="w-full h-12 mr-2" src="https://loremedia.vercel.app/src/Logo-Loremedia.svg" alt="loremedia" />
                    </Link>
                    <div className="w-full text-center bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Sign in
                            </h1>
                            <form className="space-y-4 md:space-y-6" action="">
                                <div>
                                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="username" required />
                                </div>
                                <div>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                                </div>

                                <button onClick={Sign_In_To_Dashboard} type="button" className="w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>

                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Admin