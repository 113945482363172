import React, { useEffect } from 'react';
//import NewSlider from './Slider/NewSlider';
import HomeSlider from './Slider/HomeSlider';
import 'animate.css'
// import Expertise from './Expertise/Expertise';
import About from './About/About'
import Services from './Services/Services';
import Valuer from './Valeur/Valuer';
import Clients from './Clients/Clients';
import Process from './Process/Process';
import Contact from './Contact/Contact';
// import Loading from './Loading/Loading';
import './Home.css'

const Home = () => {

  useEffect(() => {
    document.querySelector("title").textContent = "Loremedia";

  })

  const chatAiClicked = () => {
    document.querySelector(".loremedia_Ai_chat").style.display = "block";
    document.querySelector(".logoIconCLoseCHat").style.display = "block";
    document.querySelector(".logoIconChat").style.display = "none";
  }

  const chatAiClickedClose = () =>{
    document.querySelector(".loremedia_Ai_chat").style.display = "none";
    document.querySelector(".logoIconCLoseCHat").style.display = "none";
    document.querySelector(".logoIconChat").style.display = "block";

  }

  return (
    <div>


      {/*<Loading/>*/}

      <HomeSlider />

      <About />

      <Services />

      <Valuer />

      <Process />

      <Clients />

      <Contact />


      <div className='myChatSection'>

        <img onClick={chatAiClickedClose} className='logoIconCLoseCHat' src='/src/autre/close.png' alt='loremedia chat' />

        <img onClick={chatAiClicked} className='logoIconChat' src='/src/autre/chatIcon.png' alt='loremedia chat' />

        <iframe className='loremedia_Ai_chat' src='https://tawk.to/chat/66351897a5f95410285f4394/1hsvmg3q6' title='loremedia chat'></iframe>

      </div>


    </div>
  );
}

export default Home;
