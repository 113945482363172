import React from 'react'
import { useEffect } from 'react'
import Home from '../Components/Home/Home'

const Seo_Communication = () => {
    useEffect(()=>{
        document.querySelector("title").textContent = "Agence de communication Rabat"
    })
  return (
    <div>
        <Home/>
    </div>
  )
}

export default Seo_Communication