import './Services.css';

import { XMarkIcon } from '@heroicons/react/24/outline'


import AOS from 'aos';
import 'aos/dist/aos.css';


import React, { useState, useEffect } from 'react';
import app from '../../../../../firebase/firebase'; // Make sure Firebase is initialized properly
import { getFirestore, collection, onSnapshot, query, where } from 'firebase/firestore';

const Service = () => {
    
    useEffect(() => {
        AOS.init();
    }, [])

    const [designImages, setDesignImages] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchData();
        return () => unsubscribe();
    }, []);

    // Fetch the required data using Firestore
    const fetchData = () => {
        const db = getFirestore(app);
        const portfolioRef = collection(db, "portfolioServices");
        const querySnapshot = query(portfolioRef, where("imgTag", "==", "design"));

        return onSnapshot(querySnapshot, (snapshot) => {
            const images = snapshot.docs.map(doc => ({
                imgSrc: doc.data().imgSrc,
                imgDesc: doc.data().imgDesc // Add imgDesc to the fetched data
            }));
            setDesignImages(images);
        });
    }


    const showService = (imgSource, imgDescription) => {
        var herePreview = document.querySelector(".herePreview");
        var divTarget = document.querySelector('.previewImage');
        var imgTarget = document.querySelector('.imgprv');
        var iconClose = document.querySelector('.iconClose');
        divTarget.style.display = 'flex';
        imgTarget.src = imgSource;
        iconClose.style.display = 'flex';
        herePreview.style.display = 'block';
    }

    const closePreview = () => {
        var herePreview = document.querySelector(".herePreview");
        var iconClose = document.querySelector('.iconClose');
        var divTarget = document.querySelector('.previewImage');
        herePreview.style.display = 'none';
        iconClose.style.display = 'none';
        divTarget.style.display = 'none';
    }

    return (
        <>

            <div style={{display:"none"}} className='herePreview'>
                <div onClick={closePreview} className='previewImage'>
                    <img className='imgprv' src='' alt='loremedia' />
                    
                </div>
                <XMarkIcon onClick={closePreview} className='iconClose animationClose' />
            </div>





            <div className="servicesSection grid grid-cols-1 md:grid-cols-3 gap-4">
                {designImages.map((image, i) => (
                    <div data-aos="fade-up" onClick={() => showService(image.imgSrc, image.imgDesc)} key={i} className='myservices'>
                        <img className="h-auto max-w-full rounded-lg" src={image.imgSrc} alt="loremedia graphic design" />
                        <div className='hoverCover rounded-lg'></div>
                        <button className="btn"><img className='w-20' src='/src/autre/eyeIcon.png' alt='loremedia icon'/></button>
                    </div>
                ))}
            </div>

        </>
    );
};

export default Service;