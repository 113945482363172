import React, { useEffect } from 'react'
import Slider from './Slider/Slider'
import Menu from '../Menu/Menu'
import Service from './Service/Service'

const Graphic = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  useEffect(() => {
    document.querySelector("title").textContent = "Loremedia Création graphique";
  })

  return (
    <>

      <div style={{ height: "auto" }}>

        <Slider />

        <Menu/>

        <Service/>
        
      </div>

    </>
  )
}

export default Graphic