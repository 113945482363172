import React, { useEffect } from 'react'
import './About.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import AnimationImg from './img/Animation.svg'
import LogoLeremedia from './img/LogoLoremedia.svg'
import notreAgence from './img/notre-agence.png'

const About = () => {

    useEffect(() => {
        AOS.init();
    }, [])


    const showMoreAbout = () => {
        document.querySelector('.fullTextForMobile').style.display = 'block';
        document.querySelector('.buttonReadMoreForMobile').style.display = 'none';
    }

    const showMoreAboutLaptop = () => {
        document.querySelector('.hideThisOnLaptop').style.display = 'block';
        document.querySelector('.buttonReadMoreForLaptop').style.display = 'none';
    }


    return (
        <div className='AboutUsSection'>

            <div className='imgSectionAnimation'>


                <img className='animationOfBackground' src='/src/autre/anim1orange.png' alt='loremedia' />
                <img className='animationOfBackground2' src='/src/autre/anim1orange.png' alt='loremedia' />
                <img className='animationOfBackground3' src='/src/autre/anim1.png' alt='loremedia' />
                <img className='animationOfBackground4' src='/src/autre/anim1.png' alt='loremedia' />

            </div>

            <div className='AboutUsHeader'>

                <div id='notre_agence' className='LeftDiv'>
                    <h1 data-aos="fade-up" style={{ color: "#1C1A50" }} className='About_title_NotreAgence text-2xl lg:text-5xl'>NOTRE AGENCE</h1>
                    <img data-aos="fade-up" id='imageLogo' src={LogoLeremedia} alt='loremedia' />
                </div>

                <div className='RightDiv'>
                    <img id='imageRightTopAbout' data-aos="fade-up" src={AnimationImg} alt='Loremedia' style={{ float: "right" }} />
                </div>

            </div>

            <div className='LineOfAbout'></div>



            <div className='AboutUsComponent'>

                <div className='LeftAbout'>
                    <p className="myTextAbout font-normal text-base leading-7 text-gray-600 ">
                        <b>LOREMEDIA</b> est bien plus qu'une agence de communication ; nous sommes des experts spécialisés dans la création de stands, la modélisation 3D, et les plans d'architecture.
                        <br />
                        <span style={{ position: "relative", top: "6.5px" }}>
                            Notre réputation repose sur notre ingéniosité et notre capacité à répondre rapidement aux besoins de nos clients.
                            <br />
                        </span>

                        <span style={{ position: "relative", top: "13px" }}>
                            Nous proposons des solutions graphiques novatrices, parfaitement adaptées aux exigences des entreprises, les aidant ainsi à s'imposer rapidement en tant que leaders dans leur domaine!
                            <br />

                            <button onClick={showMoreAbout}
                                className="buttonReadMoreForMobile overflow-hidden relative w-32 p-2 h-12 border-none rounded-md text-sm font-bold cursor-pointer relative z-10 group"
                            >
                                En savoir plus
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-sky-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-right"
                                ></span>
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-green-300 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-right"
                                ></span>
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-green-500 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-right"
                                ></span>
                                <span
                                    className="mt-1 text-white group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10"
                                >Lire la suite</span>
                            </button>


                        </span>

                        <div className='fullTextForMobile'>

                            <span style={{ position: "relative", top: "26px" }}>
                                Notre équipe de créatifs excelle dans la conception de contenus architecturaux en 3D, de sites web percutants, ainsi que dans la réalisation graphique pour tous types de supports imprimés.
                                <br />
                            </span>

                            <span style={{ position: "relative", top: "40px" }}>
                                Notre mission est claire : concevoir, matérialiser et promouvoir des projets novateurs et créatifs pour nos clients, leur permettant ainsi de développer leur clientèle et de devenir des références incontournables dans leur secteur!
                                <br />
                            </span>

                            <span style={{ position: "relative", top: "50px" }}>
                                Dans un élan d'innovation constant, LOREMEDIA étend son expertise au domaine de l'architecture et de l'aménagement intérieur et extérieur.
                                <br />
                            </span>




                            <button data-aos="fade-up" onClick={showMoreAboutLaptop}
                                className="buttonReadMoreForLaptop overflow-hidden relative w-32 p-2 h-12 border-none rounded-md text-sm font-bold cursor-pointer relative z-10 group"
                            >
                                En savoir plus
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-sky-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-right"
                                ></span>
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-green-300 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-right"
                                ></span>
                                <span
                                    className="absolute w-36 h-32 -top-8 -left-2 bg-green-500 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-1000 duration-500 origin-right"
                                ></span>
                                <span
                                    className="mt-1 text-white group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10"
                                >Lire la suite</span>
                            </button>




                            <div className='hideThisOnLaptop'>

                                <span style={{ position: "relative", top: "60px" }}>
                                    Nous sommes convaincus que le design environnemental est essentiel pour créer des espaces à la fois inspirants et fonctionnels.
                                    <br />
                                </span>

                                <span style={{ position: "relative", top: "70px" }}>
                                    En étroite collaboration avec nos clients, notre équipe d'architectes et de designers d'intérieur s'attache à concevoir des lieux qui racontent une histoire, mariant subtilement esthétique et praticité.
                                    <br />
                                </span>

                                <span style={{ position: "relative", top: "80px" }}>
                                    Qu'il s'agisse de projets résidentiels, commerciaux ou publics, nous sommes là pour donner vie à vos idées et créer des espaces qui captivent et émerveillent, à la hauteur de vos ambitions.
                                </span>

                                <ul data-aos="fade-up" style={{ position: "relative", top: "90px", left: "1.1rem", listStyle: "circle" }}>
                                    <li><b>Conseil en communication</b></li>
                                    <li><b>Graphisme et Publicité</b></li>
                                    <li><b>Création de stands et Signalétique</b></li>
                                    <li><b>Rénovation et Aménagement</b></li>
                                    <li><b>Développement Web</b></li>
                                    <li><b>Marketing digital</b></li>
                                    <li><b>Travaux d'impression (Grand et Petit formats)</b></li>
                                </ul>

                            </div>


                        </div>
                    </p>
                </div>


                <div data-aos="fade-left" data-aos-duration="2000" className='RightAbout' >
                    <img className='imgAbout rounded-tl-3xl rounded-bl-3xl' src={notreAgence} alt='loremedia' />
                </div>


            </div>



        </div>
    )
}

export default About