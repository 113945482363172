import { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import 'animate.css'
import './Nav.css'

import {
    Bars3Icon,
    ChatBubbleBottomCenterTextIcon,
    PaintBrushIcon,
    CodeBracketIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import LangueList from './LangueList'
import LangueListMobile from './LangueListMobile'

const products = [
    { name: 'Conseil en communication', action: "Communication", icon: ChatBubbleBottomCenterTextIcon },
    { name: 'Creation graphique', action: "/portfolio/creation-graphique", icon: PaintBrushIcon },
    { name: 'Creation Siteweb', action: "/portfolio/creation-site-web", icon: CodeBracketIcon }
]

const productsImg = [
    { name: 'Creation de Stands', action: "/portfolio/stands", icon: '/src/products/signage.png' },
    { name: 'Signalétique', action: "/portfolio/signalétique", icon: '/src/products/exhibition.png' },
    { name: 'Renovation et Amenagement', action: "/portfolio/renovation-et-Amenagement", icon: '/src/products/renovation.png' },
    { name: 'Marketing Digital', action: "/marketing-digital", icon: '/src/products/digital-marketing.png' },
    { name: "Travaux D'impression", action: "/travaux-d'impression", icon: '/src/products/print.png' }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Nav = () => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const dnsDomaine = "https://www.loremedia.ma/";
    const localDnsDomaine = "http://localhost:3000/";

    const location = useLocation();

    useEffect(() => {

        const currentPath = location.pathname;

        if (window.location.href.startsWith(dnsDomaine) || window.location.href.startsWith(localDnsDomaine)) {
            switch (currentPath) {
                case "/notre_agence":
                    scrollToElement("notre_agence");
                    break;
                case "/contact":
                    scrollToElement("contactUS");
                    break
                case "/reference":
                    scrollToElement("reference");
                    break;
                default:
                    // Default behavior or additional cases if needed
                    break;
            }
        }
    }, [location.pathname]);

    const scrollToElement = (elementId) => {
        if (window.innerWidth <= 768) {
            const rightSideSection = document.getElementById("rightSideSection");
            if (rightSideSection) {
                rightSideSection.style.display = "none";
            }
        }
        const targetElement = document.getElementById(elementId);
        if (targetElement) {
            const yOffset = -50; // Adjust this value to offset the scroll position as needed
            const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };




    const [isOpen, setIsOpen] = useState(false);

    const checkMobileBars = () => {
        document.querySelector('.myBarIcon').style.display = 'none';
    }

    const checkMobileBarsClose = () => {
        document.querySelector('.myBarIcon').style.display = 'flex';
    }

    const hideRightSide = () => {
        document.getElementById("rightSideSection").style.display = "none";
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                if (mobileMenuOpen) {
                    document.querySelector('.myBarIcon').style.display = 'none';
                } else {
                    document.querySelector('.myBarIcon').style.display = 'flex';
                }
            } else {
                document.querySelector('.myBarIcon').style.display = 'none';
            }
        };

        // Initial call to handleResize
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [mobileMenuOpen]);




    return (
        <header style={{ background: "#1c1a50" }} className="shadow-lg shadow-indigo-500/10">
            <nav className="mx-auto 2xl:w-10/12 flex w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">loremedia</span>
                        <img className="h-11 w-auto" src={"https://loremedia.vercel.app/src/Logo-Loremedia.svg"} alt="loremedia" />
                    </Link>
                </div>
                <div className="myBarIcon flex lg:hidden">
                    <button
                        type="button" style={{ zIndex: "99" }}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => { setMobileMenuOpen(true); checkMobileBars(); }}

                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <Link to="notre_agence" className="NavHoverText text-sm font-semibold leading-6 text-white">
                        Notre Agence
                    </Link>

                    <Popover className="relative">
                        <div
                            className="NavHoverText flex items-center gap-x-1 text-sm font-semibold leading-6 text-white group"
                            onMouseEnter={() => setIsOpen(true)}
                            onMouseLeave={() => setIsOpen(false)}
                            style={{ cursor: "pointer" }}
                        >
                            Services
                            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </div>

                        <Transition
                            show={isOpen}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                style={{ background: "#1c1a50" }}
                                className="rounded-2xl absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3x shadow-lg ring-1 ring-white/5"
                                onMouseEnter={() => setIsOpen(true)}
                                onMouseLeave={() => setIsOpen(false)}
                            >
                                <div className="p-4 ">
                                    {products.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 DropDownTextHover"
                                        >
                                            <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                            </div>
                                            <div className="flex-auto">
                                                <Link to={item.action} className="block font-semibold text-white">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}

                                    {productsImg.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 DropDownTextHover"
                                        >
                                            <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <img src={item.icon} className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" alt='loremedia services' />
                                            </div>
                                            <div className="flex-auto">
                                                <Link to={item.action} className="block font-semibold text-white">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>


                    <Link to="/portfolio" className="NavHoverText text-sm font-semibold leading-6 text-white">
                        Portfolio
                    </Link>

                    <Link to="/reference" className="NavHoverText text-sm font-semibold leading-6 text-white">
                        References
                    </Link>

                    <Link to={"contact"} className="NavHoverText text-sm font-semibold leading-6 text-white">
                        Contact
                    </Link>
                </Popover.Group>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <Link href="#" className="text-sm font-semibold leading-6 text-white">
                        <LangueList />
                    </Link>
                </div>
            </nav>


            <Dialog style={{ background: "#1c1a50", color: "white" }} as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel id="rightSideSection" style={{ background: "#1c1a50" }} className="animate__animated animate__slideInRight fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">loremedia</span>
                            <img
                                className="h-11 w-auto"
                                src={"https://loremedia.vercel.app/src/Logo-Loremedia.svg"}
                                alt="loremedia"
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-white"
                            onClick={() => { setMobileMenuOpen(false); checkMobileBarsClose(); }}


                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Link
                                                to="notre_agence" style={{ marginLeft: "0px" }}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white NavHoverText"
                                            >
                                                Notre Agence
                                            </Link>

                                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-white NavHoverText">

                                                Services
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {[...products,].map((item) => (
                                                    <Link to={item.action}>
                                                        <Disclosure.Button
                                                            onClick={() => setMobileMenuOpen(false)}
                                                            key={item.name}

                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white NavHoverText"
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    </Link>
                                                ))}

                                                {[...productsImg,].map((item) => (
                                                    <Link to={item.action}>
                                                        <Disclosure.Button
                                                            onClick={() => setMobileMenuOpen(false)}
                                                            key={item.name}

                                                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-white NavHoverText"
                                                        >
                                                            {item.name}
                                                        </Disclosure.Button>
                                                    </Link>
                                                ))}



                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Link onClick={hideRightSide}
                                    to="/portfolio"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white NavHoverText"
                                >
                                    Portfolio
                                </Link>
                                <Link
                                    to="reference"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white NavHoverText"
                                >
                                    References
                                </Link>
                                <Link
                                    to={"contact"}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white NavHoverText"
                                >
                                    Contact
                                </Link>
                            </div>
                            <div className="py-6">
                                <Link
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-whit"
                                >
                                    <LangueListMobile />
                                </Link>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default Nav