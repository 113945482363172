import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectSEO = () => {
  const dnsDomaine = "https://www.loremedia.ma/";
  const localDnsDomaine = "http://localhost:3000/";
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (window.location.href.startsWith(dnsDomaine) || window.location.href.startsWith(localDnsDomaine)) {
      if (currentPath === "/developpement-web") {
        navigate("/portfolio/creation-site-web");
      } else if (currentPath === "/graphisme") {
        navigate("/portfolio/creation-graphique");
      }
    }
  }, [navigate]);

  return (
    <div>RedirectSEO</div>
  );
};

export default RedirectSEO;
