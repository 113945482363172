import React, { useState, useEffect } from 'react';
import './Slider.css';
import app from '../../../../../firebase/firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Slider = () => {
  const [developmentImgSrc, setDevelopmentImgSrc] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore(app);
        const docRef = doc(db, 'portfolio', 'developement'); // Assuming 'developement' is the document ID
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setDevelopmentImgSrc(data.imgSrc);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchData();

  }, []);

  return (
    <div className='relative'>
      <img className='imgSlider' src={developmentImgSrc} alt='loremedia portfolio' />
      <div className='divTextDescPortfolio'>
        <div className='block relative'>
          <h1 className='absolute text-transparent'>CRÉATION SITE WEB Rabat Maroc</h1>
          <h2 className='titlePort'>PORTFOLIO</h2>
          <span className='borderBottomPort'></span>
          <p className='paragPort'>CRÉATION SITE WEB</p>
        </div>
      </div>
    </div>
  );
}

export default Slider;
