import React, { useEffect } from 'react'
import './Portfolio.css'
import Slider from './Slider/Slider'
import Menu from './Components/Menu/Menu'
import All from './Components/All/All'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Portfolio = () => {


  useEffect(() => {
    document.querySelector("title").textContent = "Loremedia Portfolio";
    AOS.init();

  })




  return (
    <>

      <div style={{ height: "auto" }}>

        <Slider />

        <Menu />

        <All />

      </div>

    </>
  )
}

export default Portfolio