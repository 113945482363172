import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header/Header';
import LeftSide from '../../Header/LeftSide/LeftSide';
import { collection, getDocs, deleteDoc, doc, where } from 'firebase/firestore';
import { firestore } from './firebase';
import './DeleteService.css'

const DeleteService = () => {
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredServices, setFilteredServices] = useState([]);

    useEffect(() => {
        const mySession = localStorage.getItem("sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)");
        if (mySession !== 'true') {
            navigate("/anaadmin");
        }
    }, [navigate]);

    useEffect(() => {
        async function fetchServices() {
            try {
                const querySnapshot = await getDocs(collection(firestore, 'portfolioServices'));
                const servicesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setServices(servicesData);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        }

        fetchServices();
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            const filtered = services.filter(service => service.imgTag === selectedCategory);
            setFilteredServices(filtered);
        } else {
            setFilteredServices(services);
        }
    }, [selectedCategory, services]);

    async function handleDelete(id) {
        const confirmDelete = window.confirm("Are you sure you want to delete this service?");
        if (confirmDelete) {
            try {
                await deleteDoc(doc(firestore, 'portfolioServices', id));
                setServices(prevServices => prevServices.filter(service => service.id !== id));
                setFilteredServices(prevServices => prevServices.filter(service => service.id !== id));
                alert("Service deleted successfully!");
            } catch (error) {
                console.error('Error deleting service:', error);
            }
        }
    }

    return (
        <div className='Dashboard'>
            <Header />
            <LeftSide />
            <div className='HomeDashboard p-2'>
                <div className="myFilterDashService flex justify-center mt-8">
                    <nav className="flex space-x-4 border">
                        <button
                            className={`filter-btn ${selectedCategory === null ? 'active' : ''}`}
                            onClick={() => setSelectedCategory(null)}
                        >
                            All
                        </button>
                        <button
                            className={`filter-btn ${selectedCategory === 'design' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('design')}
                        >
                            Design
                        </button>
                        <button
                            className={`filter-btn ${selectedCategory === 'developement' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('developement')}
                        >
                            Development
                        </button>
                        <button
                            className={`filter-btn ${selectedCategory === 'stands' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('stands')}
                        >
                            Stands
                        </button>
                        <button
                            className={`filter-btn ${selectedCategory === 'signaletique' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('signaletique')}
                        >
                            Signalétique
                        </button>
                        <button
                            className={`filter-btn ${selectedCategory === 'renovation' ? 'active' : ''}`}
                            onClick={() => setSelectedCategory('renovation')}
                        >
                            Renovation
                        </button>
                    </nav>
                </div>

                <div className="ServicesSectionDash grid grid-cols-3 gap-4 mt-8">
                    {filteredServices.map(service => (
                        <div key={service.id} className="MyService p-3 shadow-black rounded-lg shadow">
                            <img src={service.imgSrc} alt={service.imgTag} className="h-40 w-full object-cover mb-2" />
                            <p className="text-lg font-semibold">{service.imgTag}</p>
                            <p className="text-sm">{service.imgDesc}</p>
                            <button className="btn btnDelete bg-red-600 text-white p-1 px-2 rounded-lg mt-2" onClick={() => handleDelete(service.id)}>Delete</button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DeleteService;
