import React from 'react'

import './MotionGraphic.css'

import MotionSlider from './img/slider.jpg'
import CoverVideo1 from './img/video1.avif'
import IconVideo from './img/iconVideo.png'
import CoverVideo2 from './img/video2.jpg'
import CloseIcon from './img/closeIcon.png'
import Menu from '../Menu/Menu'

const MotionGraphic = () => {

    const video1 = 'https://player.vimeo.com/video/536866060?h=b1921bfdef';
    const video2 = 'https://player.vimeo.com/video/709278443?h=559273d0e7';

    const ShowVideo1 = () =>{
        document.querySelector(".myVideoPreview").style.display = 'flex';
        document.querySelector(".myIframeVideo").src = video1;
    }

    const ShowVideo2 = () =>{
        document.querySelector(".myVideoPreview").style.display = 'flex';
        document.querySelector(".myIframeVideo").src = video2;
    }

    const CloseVideo = () =>{
        document.querySelector(".myVideoPreview").style.display = 'none';
        document.querySelector(".myIframeVideo").src = '';
    }


    return (
        <div>

            <div className='myVideoPreview'>
                <img onClick={CloseVideo} className='closeIconVideo' src={CloseIcon} alt='loremedia video'/>
                <iframe className='myIframeVideo' title="vimeo-player" src="https://player.vimeo.com/video/709278443?h=559273d0e7" width="640" height="360" frameborder="0" allowfullscreen></iframe>
            </div>



            <div className='relative'>
                <img className='imgSlider' src={MotionSlider} alt='loremedia portfolio' />
                <div className='divTextDescPortfolio'>
                    <div className='block relative'>
                        <h1 className='titlePort'>PORTFOLIO</h1>
                        <span className='borderBottomPort'></span>
                        <p className='paragPort'>MOTION GRAPHIQUE</p>
                    </div>
                </div>
            </div>

            <Menu/>



            <div className="servicesSection grid grid-cols-1 md:grid-cols-3 gap-4">

                <div onClick={ShowVideo1} className='myservices'>
                    <img className="h-auto max-w-full rounded-lg" src={CoverVideo1} alt="loremedia graphic design" />
                    <div className='hoverCover rounded-lg'></div>
                    <button className="btn">
                        <img className='w-20' src={IconVideo} alt='loremedia video' />
                    </button>
                </div>


                <div onClick={ShowVideo2} className='myservices'>
                    <img className="h-auto max-w-full rounded-lg" src={CoverVideo2} alt="loremedia graphic design" />
                    <div className='hoverCover rounded-lg'></div>
                    <button className="btn">
                        <img className='w-20' src={IconVideo} alt='loremedia video' />
                    </button>
                </div>

            </div>



        </div>



    )
}

export default MotionGraphic