import React, { useEffect } from 'react'

const Politique = () => {
  useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })

  return (
    <div className='w-10/12 m-auto'>
      <h1 style={{ color: "#1C1A50" }} className='text-center text-4xl font-bold mt-8 mb-8'>Politique de Confidentialité</h1>

      <div className='text-left'>
        <p>Nous accordons une grande importance à la confidentialité de nos visiteurs. Cette politique de confidentialité documente les types d'informations personnelles reçues et collectées par notre site Web et comment elles sont utilisées.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Collecte et Utilisation des Informations</h2>
        <p>Nous recueillons des informations personnelles lorsque vous vous inscrivez à notre newsletter ou remplissez un formulaire de contact. Ces informations peuvent inclure votre nom, votre adresse e-mail et votre numéro de téléphone. Nous utilisons ces informations pour vous envoyer des mises à jour sur nos services et répondre à vos demandes de renseignements.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Cookies et Technologies Similaires</h2>
        <p>Notre site Web utilise des cookies pour améliorer l'expérience de l'utilisateur. Les cookies sont de petits fichiers texte placés sur votre appareil qui nous aident à analyser l'utilisation du site Web et à fournir des fonctionnalités améliorées.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Partage d'Informations</h2>
        <p>Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers. Cependant, nous pouvons partager vos informations avec des tiers de confiance qui nous aident à exploiter notre site Web ou à mener nos activités, tant que ces parties acceptent de garder ces informations confidentielles.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Sécurité</h2>
        <p>Nous mettons en œuvre diverses mesures de sécurité pour préserver la sécurité de vos informations personnelles. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée. Nous ne pouvons donc pas garantir une sécurité absolue.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Modifications de la Politique de Confidentialité</h2>
        <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance de tout changement. En continuant à utiliser notre site Web après la publication de ces modifications, vous acceptez ces changements.</p>

        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-4'>Contactez-Nous</h2>
        <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter en utilisant les informations ci-dessous :</p>
        <p>Loremedia</p>
        <p>Imm 30, Apt N8°, Rue Moulay Ahmed Loukili, Hassan - Rabat</p>
        <p>contact@loremedia.ma</p>
        <p className='mb-6'><a href='tel:+212 654615707'>+212 654615707</a></p>
      </div>
    </div>
  )
}

export default Politique