import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Deve_Rabat = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.querySelector("title").textContent = "Création siteweb Rabat";
        navigate('/portfolio/creation-site-web');
    })

    return (
        <>
        <title>Création siteweb Rabat</title>
        <div>
            <h1>Création siteweb Rabat</h1>
        </div>
        
        </>
    )
}

export default Deve_Rabat