import React, { useEffect } from 'react'
import './Menu.css'
import { Link } from 'react-router-dom'

const Menu = () => {

    useEffect(() => {
        const allowedPaths = [
            "/portfolio",
            "/portfolio/creation-graphique",
            "/portfolio/creation-site-web",
            "/portfolio/stands",
            "/portfolio/signalétique",
            "/portfolio/renovation-et-Amenagement",
            "/portfolio/motion-graphic"
        ];
    
        const currentPath = decodeURIComponent(window.location.pathname); // Decode the path
        const classNameMap = {
            "/portfolio": "check_tout",
            "/portfolio/creation-graphique": "check_graphic",
            "/portfolio/creation-site-web": "check_siteweb",
            "/portfolio/stands": "check_stands",
            "/portfolio/signalétique": "check_signal",
            "/portfolio/renovation-et-Amenagement": "check_renova",
            "/portfolio/motion-graphic": "check_motion"
        };
    
        if (allowedPaths.includes(currentPath)) {
            const className = classNameMap[currentPath] || "check_signal";
            const element = document.querySelector(`.${className}`);
            if (element) {
                element.className = `${className} checkActive mb-2 mr-2`;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    



    return (
        <div className="myListPortfolio mt-4 flex justify-center">
            <ul className="flex myListPortfolioMobile">
                <li className="check_tout mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio"><h2>Tous</h2></Link></div>
                </li>
                <li className="check_graphic mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/creation-graphique"><h2>Création graphique</h2></Link></div>
                </li>
                <li className="check_siteweb mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/creation-site-web"><h2>Création Site Web</h2></Link></div>
                </li>
                <li className="check_stands mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/stands"><h2>Stands</h2></Link></div>
                </li>
                <li className="check_signal mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/signalétique"><h2>Signalétique</h2></Link></div>
                </li>
                <li className="check_renova mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/renovation-et-Amenagement"><h2>Rénovation et Aménagement</h2></Link></div>
                </li>
                <li className="check_motion mb-2 mr-2">
                    <div className="px-4 py-2 rounded-lg cursor-pointer"><Link to="/portfolio/motion-graphic"><h2>Motion Graphique</h2></Link></div>
                </li>


            </ul>
        </div>

    )
}

export default Menu