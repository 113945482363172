import React from 'react'
import './Valeur.css'

import listenImg from './img/listen.png'
import reactivityImg from './img/trace.png'
import creativity from './img/design-thinking.png'
import quality from './img/quality-service.png'

const Valuer = () => {
    return (
        <div className='relative'>

            <img className='animationOfBackground' src='/src/autre/anim1.png' alt='loremedia' />
            <img className='animationOfBackground2' src='/src/autre/anim1.png' alt='loremedia' />
            <img className='animationOfBackground3' src='/src/autre/anim1.png' alt='loremedia' />
            <img className='animationOfBackground4' src='/src/autre/anim1.png' alt='loremedia' />

            <div className="ValeurSection p-4">
                <div className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-20">
                    <div className="blur-[106px] h-56 bg-gradient-to-br  to-purple-400 from-blue-700"></div>
                    <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400  to-indigo-600"></div>
                </div>
                <div className="nosValuersContainer mx-auto px-6 md:px-12 xl:px-6">

                    <h1  data-aos="fade-up" style={{ color: "#1c1a50" }} className="titleService text-left text-4xl font-extrabold leading-none tracking-tight md:text-3xl lg:text-4xl text-white">
                        <div className='borderTopText'></div>
                        <span className='text-2xl lg:text-5xl serviceTitle'>NOS VALEURS</span>
                    </h1>

                    <div
                        className="serviceSectionDivs mt-16 grid divide-x divide-y  divide-gray-700 overflow-hidden  rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
                        <div className="group relative backValeurs transition hover:z-[1] hover:shadow-2xl  hover:shadow-gray-600/10">
                            <div className="ServicesDivs relative space-y-8 py-12 p-8">
                                <img src={listenImg} width="200" height="200" className="imgValeurAnimation w-12 h-12" style={{ color: "transparent" }} alt="loremedia" />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">ÉCOUTE</h5>
                                    <p style={{ textAlign: "justify" }} className="text-gray-300">
                                        Mieux vous connaître, c’est mieux comprendre vos
                                        besoins et vos envies. Constatez l’évolution de votre
                                        projet et donnez votre ressenti à une équipe qui sait
                                        écouter. C’est ensemble que nous arriverons au but
                                        visé.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="group relative backValeurs transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                                <img src={reactivityImg} loading="lazy" width="200" height="200" className="imgValeurAnimation w-12 h-12" style={{ color: "transparent" }} alt="loremedia" />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">RÉACTIVITÉ</h5>
                                    <p style={{ textAlign: "justify" }} className="text-gray-300">
                                        Notre équipe se distingue par son action rapide, sa rigueur et son professionnalisme, respectant vos délais avec créativité et efficacité, même dans l'urgence, en mettant en œuvre des solutions innovantes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="group relative backValeurs transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                                <img src={creativity} loading="lazy" width="200" height="200" className="imgValeurAnimation w-12 h-12" style={{ color: "transparent" }} alt="loremedia" />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">CRÉATIVITÉ</h5>
                                    <p style={{ textAlign: "justify" }} className="text-gray-300">
                                        Innée dans notre approche, nous concevons des solutions innovantes, passionnés par les métiers graphiques, pour des visuels modernes et dynamiques qui reflètent votre image, apportant une touche unique à chaque projet.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="group relative backValeurs transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
                            <div className="relative space-y-8 py-12 p-8">
                                <img src={quality} loading="lazy" width="200" height="200" className="imgValeurAnimation w-12 h-12" style={{ color: "transparent" }} alt="loremedia" />
                                <div className="space-y-2">
                                    <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">QUALITÉ</h5>
                                    <p style={{ textAlign: "justify" }} className="text-gray-300">
                                        Perfectionnistes dans l’âme, nous pensons que les
                                        petits détails feront la différence. Nous passons au
                                        peigne fin votre création et nous prenons soin de
                                        livrer un travail de qualité quels que soient les délais
                                        de réalisations
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Valuer