import React, { useRef, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../../Admin/Dashboard/Preview/Pages/firebase';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline'

import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const HomeSlider = () => {

  const [slidersImg, setSlidersImg] = useState([]);

  useEffect(() => {
    async function fetchHomePage() {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'homePage'));
        const homePageData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSlidersImg(homePageData);
      } catch (error) {
        console.error('Error fetching homePage:', error);
      }
    }

    fetchHomePage();
  }, []);


  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <>

      <div style={{fontSize:"0.1px"}} className='absolute text-transparent'>
        <h1>Création des Stands Rabat</h1>
        <h2>Création d'Événements Rabat</h2>
        <h3>Création de Site Web Rabat</h3>
        <h4>Signalétique Impactante Rabat</h4>
        <h5>Création des Stands Maroc, Création d'Événements Maroc, Création de Site Web Maroc, Signalétique Impactante Maroc</h5>
      </div>

      <Swiper
        spaceBetween={30}
        centeredSlides={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: false,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper shadow-sm shadow-indigo-500/30"
      >

        {slidersImg.map((data, i) => {
          return (
            <SwiperSlide key={i}>
              <img src={data.imgSrc} alt='loremedia' />
            </SwiperSlide>
          )
        })}

        <div style={{ display: "none" }} className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>

      <h1 className='arrowDownAnimation' style={{ width: "2rem", margin: "auto", marginTop: "1rem" }}>
        <ChevronDoubleDownIcon />
      </h1>

    </>
  )
}

export default HomeSlider