import React, { useEffect } from 'react';
import './Communicatin.css';
import 'animate.css';

const TravauxImpr = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  })
 
  return (
    <div className='Communication'>
      <img className='ComSlider' src='/src/autre/print.png' alt='loremedia' />

      <div className='ComSection'>
        <div className='Comdiv1'>
          <p className='full_com_text'>
            <h2 style={{ color: "#1C1A50" }} className="animate__animated animate__bounceInLeft comTitle my-6 text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl">
              IMPRESSION SUPPORTS PUBLICITAIRES
            </h2>
            <div className='animate__animated animate__bounceInLeft theGreenLine'></div>

            <p style={{color:"#529512"}} className='animate__animated animate__bounceInLeft com_paragraph_1'>
              UN IMPRIMEUR A VOTRE ÉCOUTE POUR UN
              CONSEIL PERSONNALISÉ.
            </p>

            <h3 className='animate__animated animate__bounceInLeft mt-2 com_paragraph_2'>
              Laissez vous guider de la création graphique à
              l’impression de l’ensemble de votre communication,
              nous cherchons sans cesse à nous adapter et
              innover en présentant toujours plus de solutions,
              de produits, et de services : une large gamme de
              supports de qualité, de papiers recyclés, impression
              et livraison en J2+, une livraison sur plusieurs points
              en France Métropolitaine, des devis sur mesure pour
              des finitions et façonnage hors gamme.
            </h3>

            <p style={{color:"#529512"}} className='animate__animated animate__bounceInLeft com_paragraph_3 mt-2'>
              UNE LARGE SÉLECTION DE SUPPORTS POUR MIEUX
              VOUS DÉMARQUER.
            </p>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
              Nous vous apportons des solutions d’impression de
              qualité, des devis clairs et rapides, et optimisons
              vos solutions d’impression au meilleur prix pour tous
              vos documents & supports (du plus simple au plus
              créatif).
            </p>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
              Grâce à notre large champs d’action et nos nombreux
              supports publicitaires modernisons votre image,
              soyez omniprésent en vous démarquant avec des
              moyens novateurs.
            </p>

            <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-1'>
              N’hésitez pas à nous contacter afin de vous orienter
              pour choisir le meilleur support en fonction de vos
              objectifs.
            </p>

          </p>
        </div>
        <img className="animate__animated animate__bounceInRight imgSection" src="/src/autre/imprSquare.jpg" alt="" />
      </div>



    </div>
  );
}

export default TravauxImpr;
