import React, { useState } from 'react';

const LangueListMobile = () => {

    
  const [selectedLanguage, setSelectedLanguage] = useState('fr');

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    document.querySelector(".LangueList").style.display = "none"
    // You can add logic here to handle language change in your application
  };

 
  // code to display langues
  var LangueListcheck = 0
  const SelectLangueListMobile = () =>{
    var LangueList = document.querySelector(".LangueListMobile");
    if(LangueListcheck === 0){
        LangueList.style.display = "block";
        return LangueListcheck = 1;
    }
    else{
        LangueList.style.display = "none";
        return LangueListcheck = 0;
    }
  }


  return (
    <div className="relative">
    <button onClick={SelectLangueListMobile} className="flex items-center space-x-2 bg-white text-gray-700 py-2 px-4 rounded-full shadow-md focus:outline-none">
      <img
        src={`https://www.loremedia.ma/src/flags/${selectedLanguage}.webp`}
        alt="Selected language flag loremedia"
        className="h-4 w-4"
      />
      <span>{selectedLanguage === 'fr' ? 'Français' : selectedLanguage === 'en' ? 'English' : 'العربية'}</span>

    </button>

    <ul style={{display:"none"}} className="LangueListMobile absolute left-0 mt-2 w-32 bg-white shadow-md rounded-md overflow-hidden z-10">
      <li>
        <button
          className={`block text-left py-2 px-4 text-gray-700 hover:bg-gray-100 w-full focus:outline-none ${
            selectedLanguage === 'fr' && 'font-bold'
          }`}
          onClick={() => handleLanguageChange('fr')}
        >
          <img src="https://www.loremedia.ma/src/flags/fr.webp" alt="French flag loremedia" className="h-4 w-4 mr-2 inline-block" /> Français
        </button>
      </li>
      <li>
        <button
          className={`block text-left py-2 px-4 text-gray-700 hover:bg-gray-100 w-full focus:outline-none ${
            selectedLanguage === 'en' && 'font-bold'
          }`}
          onClick={() => handleLanguageChange('en')}
        >
          <img src="https://www.loremedia.ma/src/flags/en.webp" alt="English flag loremedia" className="h-4 w-4 mr-2 inline-block" /> English
        </button>
      </li>
      <li>
        <button
          className={`block text-left py-2 px-4 text-gray-700 hover:bg-gray-100 w-full focus:outline-none ${
            selectedLanguage === 'ar' && 'font-bold'
          }`}
          onClick={() => handleLanguageChange('ar')}
        >
          <img src="https://www.loremedia.ma/src/flags/ar.webp" alt="Arabic flag loremedia" className="h-4 w-4 mr-2 inline-block" /> العربية
        </button>
      </li>
    </ul>
  </div>
  )
}

export default LangueListMobile