import React, { useState } from 'react';
import './LeftSide.css';
import { Link } from 'react-router-dom';

const LeftSide = () => {
  const [portfolioOpen, setPortfolioOpen] = useState(false);
  const [homeOpen, setHomeOpen] = useState(false);
  const [slidersOpen, setSlidersOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [homeServicesOpen, setHomeServicesOpen] = useState(false);

  const togglePortfolio = () => {
    setPortfolioOpen(!portfolioOpen);
  };

  const toggleHomePage = () => {
    setHomeOpen(!homeOpen);
  };

  const toggleSliderHome = () => {
    setSlidersOpen(!slidersOpen);
  };

  const toggleServicesHome = () => {
    setHomeServicesOpen(!homeServicesOpen);
  };

  const toggleService = () => {
    setServicesOpen(!servicesOpen);
  };

  return (
    <div id='LeftSide' className="bg-gray-800 h-full w-1/4">
      <div className="text-white text-lg font-semibold py-4 px-6">Dashboard</div>
      <div className="text-white px-6 pb-4">
        <div className="mb-4">
          <button onClick={togglePortfolio} className="flex items-center justify-between w-full bg-gray-700 rounded-md py-2 px-4 focus:outline-none">
            <span>Portfolio</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
          </button>
          <div className={portfolioOpen ? "mt-2" : "hidden mt-2"}>
            <ul className="flex flex-col">
              <Link to="/anaadmin/dashboard/addSlider">
                <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Add Slider</li>
              </Link>
              <button onClick={toggleService} className="flex items-center justify-between w-full rounded-md py-2 px-4 focus:outline-none">
                <span>Service</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>
              <div className={servicesOpen ? "mt-2" : "hidden mt-2"}>
                <ul className="flex flex-col">
                  <Link to="/anaadmin/dashboard/addService">
                    <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Add Service</li>
                  </Link>
                  <Link to="/anaadmin/dashboard/deleteService">
                    <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Delete Service</li>
                  </Link>
                  <Link to="/anaadmin/dashboard/updateService">
                    <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Update Service</li>
                  </Link>
                </ul>
              </div>
            </ul>
          </div>
        </div>

        <div className="mb-4">
          <button onClick={toggleHomePage} className="flex items-center justify-between w-full bg-gray-700 rounded-md py-2 px-4 focus:outline-none">
            <span>Home Page</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
          </button>
          <div className={homeOpen ? "mt-2" : "hidden mt-2"}>
            <ul className="flex flex-col">
              <div className="mb-4">
                <button onClick={toggleSliderHome} className="flex items-center justify-between w-full rounded-md py-2 px-4 focus:outline-none">
                  <span>Home Sliders</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                  </svg>
                </button>
                <div className={slidersOpen ? "mt-2" : "hidden mt-2"}>
                  <ul className="flex flex-col">
                    <Link to="/anaadmin/dashboard/addSlider_home">
                      <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Add Slider Home</li>
                    </Link>
                    <Link to="/anaadmin/dashboard/deleteSlider_home">
                      <li className="py-2 px-4 hover:bg-gray-700 focus:outline-none rounded-md my-1">Delete Slider Home</li>
                    </Link>
                  </ul>
                </div>
              </div>

            
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
