import React, { useState, useEffect } from 'react';
import './Slider.css';
import app from '../../../firebase/firebase';
import { getFirestore, collection, onSnapshot, query, where } from 'firebase/firestore';

const Slider = () => {
  const [designImages, setDesignImages] = useState([]);

  useEffect(() => {
    const unsubscribe = fetchData();
    return () => unsubscribe();
  }, []);

  // Fetch the required data using Firestore
  const fetchData = () => {
    const db = getFirestore(app);
    const portfolioRef = collection(db, "portfolio");
    const querySnapshot = query(portfolioRef, where("imgTarget", "==", "slider"), where("imgTag", "==", "developement"));

    return onSnapshot(querySnapshot, (snapshot) => {
      const images = snapshot.docs.map(doc => doc.data().imgSrc);
      setDesignImages(images);
    });
  }

  return (
    <div className='relative'>
      {designImages.map((imgSrc, index) => (
        <img key={index} className='imgSlider' src={"https://i.ibb.co/rfwt4Pc/slider.jpg"} alt={`Design ${index}`} />
      ))}
      <div className='divTextDescPortfolio'>
        <div className='block relative'>
          <h1 className='titlePort'>PORTFOLIO</h1>
          <span className='borderBottomPort'></span>
          <p className='paragPort'>Nous croyons que notre travail parle de lui-même. Parcourez nos projets les plus récents ci-dessous.</p>
        </div>
      </div>
    </div>
  );
}

export default Slider;
