import React, { useEffect } from 'react';

const Faq = () => {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    })
  return (
    <div style={{width:"80%"}} className=' m-auto'>
      <h1 style={{ color: "#1C1A50" }} className='text-center text-4xl font-bold mt-8 mb-8'>Aide & FAQ</h1>

      <div className='text-left'>
        <h3 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mt-8 mb-8'>Frequently Asked Questions</h3>

        <div className='mb-8'>
          <h4 style={{ color: "#1C1A50" }} className='text-xl font-semibold mb-4'>Quand la demande me parviendra-t-elle?</h4>
          <p>Le délai de réception dépend de la compagnie maritime choisie et du responsable de la livraison. Généralement, cela prend de 1 à 3 jours pour que la commande soit confirmée.</p>
        </div>

        <div className='mb-8'>
          <h4 style={{ color: "#1C1A50" }} className='text-xl font-semibold mb-4'>En cas de défaut de fabrication du produit?</h4>
          <p>Veuillez consulter notre politique de retour pour connaître les conditions de retour et d'échange. En cas de défaut du produit, les frais d'expédition de retour sont à notre charge et seront remboursés au client. Cependant, la valeur du produit ne sera pas remboursée selon notre politique de retour.</p>
        </div>

        <div className='mb-8'>
          <h4 style={{ color: "#1C1A50" }} className='text-xl font-semibold mb-4'>Je souhaite commander de grandes quantités. Y a-t-il une remise?</h4>
          <p>Oui, nous offrons des remises sur les commandes en gros. Les remises varient généralement entre 10% et 20% sur certains produits. Pour plus d'informations, veuillez nous contacter directement via notre page de contact.</p>
        </div>
      </div>

      <div className='mt-16'>
        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mb-8'>À Propos de LOREMEDIA</h2>
        <p>LOREMEDIA est bien plus qu'une agence de communication ; nous sommes des experts spécialisés dans la création de stands, la modélisation 3D et les plans d'architecture. Notre réputation repose sur notre ingéniosité et notre capacité à répondre rapidement aux besoins de nos clients. Nous proposons des solutions graphiques novatrices parfaitement adaptées aux exigences des entreprises, les aidant ainsi à s'imposer rapidement en tant que leaders dans leur domaine!</p>
        <p>Notre équipe de créatifs excelle dans la conception de contenus architecturaux en 3D, de sites web percutants, ainsi que dans la réalisation graphique pour tous types de supports imprimés. Notre mission est claire : concevoir, matérialiser et promouvoir des projets novateurs et créatifs pour nos clients, leur permettant ainsi de développer leur clientèle et de devenir des références incontournables dans leur secteur!</p>
        <p>Dans un élan d'innovation constant, LOREMEDIA étend son expertise au domaine de l'architecture et de l'aménagement intérieur et extérieur. Nous sommes convaincus que le design environnemental est essentiel pour créer des espaces à la fois inspirants et fonctionnels. En étroite collaboration avec nos clients, notre équipe d'architectes et de designers d'intérieur s'attache à concevoir des lieux qui racontent une histoire, mariant subtilement esthétique et praticité.</p>
        <p>Qu'il s'agisse de projets résidentiels, commerciaux ou publics, nous sommes là pour donner vie à vos idées et créer des espaces qui captivent et émerveillent, à la hauteur de vos ambitions.</p>
      </div>

      <div className='mt-16 mb-16'>
        <h2 style={{ color: "#1C1A50" }} className='text-2xl font-semibold mb-8'>Services proposés</h2>
        <ul className='list-disc list-inside'>
          <li>Conseil en communication</li>
          <li>Graphisme et Publicité</li>
          <li>Création de stands et Signalétique</li>
          <li>Rénovation et Aménagement</li>
          <li>Développement Web</li>
          <li>Marketing digital</li>
          <li>Travaux d'impression (Grand et Petit formats)</li>
        </ul>
      </div>
    </div>
  );
};

export default Faq;
