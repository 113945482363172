import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {

    // useEffect(()=>{
    //     document.querySelector('#FooterSection').style.display = 'none';
    //     setTimeout(() => {
    //         document.querySelector('#FooterSection').style.display = 'flex';
    //       }, "5000");
    // })


    return (


        <footer id="FooterSection" style={{ background: "#1C1A50" }} className="bg-white w-full">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
            <div className="FooterSection mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <Link to="/" className="flex items-center">
                            <img src="/src/Logo-Loremedia.svg" className="h-12" alt="FlowBite Logo" />
                        </Link>
                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-white">Services</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className='mb-2'>
                                    <Link to="/Communication" className="hover:underline">Conseil en communication</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/portfolio/creation-graphique" className="hover:underline">Création graphique</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/portfolio/creation-site-web" className="hover:underline">Création Siteweb</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/portfolio/stands" className="hover:underline">Stands</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/portfolio/signalétique" className="hover:underline">Signalétique</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/portfolio/renovation-et-Amenagement" className="hover:underline">Rénovation et Aménagement</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/marketing-digital" className="hover:underline">Marketing Digital</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/travaux-d'impression" className="hover:underline">Travaux D'impression   </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-white">Legal</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className='mb-2'>
                                    <Link to="/faq" className="hover:underline ">FAQ</Link>
                                </li>
                                <li className='mb-2'>
                                    <Link to="/politique" className="hover:underline">Politique de Confidentialité</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-semibold text-white">Contact</h2>
                            <ul className="text-gray-400 font-medium">
                                <li className="mb-4">
                                    <Link to="/contact" className="hover:underline">Contactez nous</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-gray-400 sm:text-center">© 2024 <Link to="/" className="hover:underline">Loremedia™</Link>. All Rights Reserved.
                    </span>
                    <div className="flex mt-4 sm:justify-center sm:mt-0">
                        <Link target='_blank' to="https://www.instagram.com/loremediacommunication" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                            <i className="fa-brands fa-instagram"></i>
                            <span className="sr-only">Instagram page</span>
                        </Link>
                        <Link target='_blank' to="https://www.facebook.com/Loremediacommunication" className="text-gray-400 hover:text-gray-900 dark:hover:text-white ms-5">
                            <i className="fa-brands fa-facebook"></i>
                        </Link>
                        <Link target='_blank' to="https://www.linkedin.com/company/loremedia-agency/" className="text-gray-400 hover:text-gray-900 dark:hover:text-white ms-5">
                            <i className="fa-brands fa-linkedin"></i>
                        </Link>
                        <Link to="#" className="text-gray-400 hover:text-gray-900 dark:hover:text-white ms-5">
                            <i className="fa-brands fa-whatsapp"></i>
                        </Link>

                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer