import React from 'react'
import './Process.css'


const Process = () => {
    return (

        <section className="bg-white text-white ProcessContainer">
            <div className="ProcessDiv py-8 px-4 mx-auto sm:py-16 lg:px-6">
                <div data-aos="fade-up" className="notreProcessForDesktop max-w-screen-md mb-8 lg:mb-16">
                    <div className='borderTopTextProcess'></div>
                    <h2  className="ProcessTitle text-2xl lg:text-5xl text-white">NOTRE PROCESS</h2>
                </div>
                <div className="MyProcessContent space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-ear"><path d="M6 8.5a6.5 6.5 0 1 1 13 0c0 6-6 6-6 10a3.5 3.5 0 1 1-7 0" /><path d="M15 8.5a2.5 2.5 0 0 0-5 0v1a2 2 0 1 1 0 4" /></svg>
                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Rencontre et écoute</h3>
                        <p className="text-gray-300">
                        Plutôt thé ou café ? Discutons ensemble pour mieux comprendre vos besoins. Vous connaître, c’est connaître votre projet et vos envies.    
                        </p>
                    </div>
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-search"><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M4.268 21a2 2 0 0 0 1.727 1H18a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3"/><path d="m9 18-1.5-1.5"/><circle cx="5" cy="14" r="3"/></svg>                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Phase de recherche</h3>
                        <p className="text-gray-300">Nos créatifs passeront derrière leurs écrans pour réfléchir au visuel idéal ! Couleurs, typographies, mise en page... Nous essayons tout ce qui est possible !</p>
                    </div>
                    
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-monitor-smartphone"><path d="M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8"/><path d="M10 19v-3.96 3.15"/><path d="M7 19h5"/><rect width="6" height="10" x="16" y="12" rx="2"/></svg>                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Création et développement</h3>
                        <p className="text-gray-300">Une fois « l’idée » trouvée, nos graphistes passent à l’action pour leur plus grand bonheur ! Nous réaliserons votre projet en respectant l’identité visuelle en place, le cas échéant.</p>
                    </div>

                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-bike"><circle cx="18.5" cy="17.5" r="3.5"/><circle cx="5.5" cy="17.5" r="3.5"/><circle cx="15" cy="5" r="1"/><path d="M12 17.5V14l-3-3 4-3 2 3h2"/></svg>                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Echange et optimisation</h3>
                        <p className="text-gray-300">Vous recevrez une première version en accord avec votre brief. Vous avez la possibilité de demander tous les ajustements que vous souhaitez. Nous pourrons vous conseiller et vous guider, c’est ensemble que nous arriverons au but visé.</p>
                    </div>


                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-truck"><path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2"/><path d="M15 18H9"/><path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14"/><circle cx="17" cy="18" r="2"/><circle cx="7" cy="18" r="2"/></svg>                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Livraison finale dans les délais</h3>
                        <p className="text-gray-300">Nous livrons toutes nos créations en respectant vos délais. Quels que soient vos demandes et vos délais, la rigueur est mise à l’honneur !</p>
                    </div>
                    <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="500">
                        <div className="lesIconsOfProcess flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-laugh"><circle cx="12" cy="12" r="10"/><path d="M18 13a6 6 0 0 1-6 5 6 6 0 0 1-6-5h12Z"/><line x1="9" x2="9.01" y1="9" y2="9"/><line x1="15" x2="15.01" y1="9" y2="9"/></svg>                        </div>
                        <h3 className="mb-2 text-xl font-bold dark:text-white">Client 100% satisfaits !</h3>
                        <p className="text-gray-300">Quelle joie de voir nos clients heureux et fiers de présenter leur communication made in LOREMEDIA.</p>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Process