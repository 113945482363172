import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import LeftSide from './Header/LeftSide/LeftSide';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const mySession = localStorage.getItem(
      "sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)"
    );
    if (mySession !== 'true') {
      navigate("/anaadmin");
    }
  }, [navigate]);


  return (
    <div className='Dashboard'>
      <Header />
      <LeftSide />
      <div className='HomeDashboard'>
        <iframe src='/portfolio' title='test'></iframe>
      </div>
    </div>
  );
};

export default Dashboard;
