import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Services.css'
import { Link } from 'react-router-dom';

import standsImg from './img/stands.jpg'

const Services = () => {
    useEffect(() => {
        AOS.init();
    }, [])

    const ourServices = [
        { img: "https://i.pinimg.com/originals/21/bd/ec/21bdece6ec73f429b46fa628ce0bed90.jpg", title: "Conseil en communication", href: "Communication", description: "Notre équipe de professionnels est à votre disposition pour vous apporter les meilleurs conseils." },
        { img: "https://f.hellowork.com/seo/domaine/graphisme.jpeg", title: "Création Graphique", href: "/portfolio/creation-graphique", description: "Chez LOREMEDIA, nous sculptons des visions en œuvres d'art graphiques qui captivent et inspirent." },
        { img: "https://thethistlehouse.com/wp-content/uploads/2021/09/AdobeStock_241083104-1400x934-1.jpg", title: "Création de Site Web", href: "/portfolio/creation-site-web", description: "LOREMEDIA : L'innovation digitale à votre service, où chaque site web devient une expérience inoubliable." },
        { img: standsImg, title: "Stand d'Exposition", href: "/portfolio/stands", description: "Avec LOREMEDIA, les stands d'exposition deviennent des temples de l'innovation visuelle, captivant les spectateurs dès le premier regard." },
        { img: "/src/autre/signalétique-square.png", title: "Signalétique", href: "/portfolio/signalétique", description: "LOREMEDIA : Des signes qui racontent votre histoire, guidant les voyageurs vers de nouveaux horizons avec chaque panneau." },
        { img: "https://thethistlehouse.com/wp-content/uploads/2021/09/AdobeStock_241083104-1400x934-1.jpg", title: "Rénovation et Aménagement", href: "/portfolio/renovation-et-Amenagement", description: "LOREMEDIA transforme les espaces en œuvres d'art fonctionnelles, éveillant des émotions et invitant à l'aventure." },
    ]



    return (
        <div className="myServicesSection">

            <h1 data-aos="fade-up" className="titleService ms-12 text-left text-4xl font-extrabold leading-none tracking-tight md:text-3xl lg:text-4xl text-white">
                <div className='borderTopText'></div>
                <span className='text-2xl lg:text-5xl serviceTitle'>SERVICES</span>
            </h1>

            <div className='myServicesSectionStyle'>



                <div className='ourServices'>


                    {ourServices.map((data, i) => {
                        return (

                            <Link to={data.href}>
                                <div key={i} style={{ position: "relative" }} className="myImageHover max-w-sm mb-10 bg-white border border-gray-200 rounded-lg shadow dark:bg-white dark:border-gray-700">
                                    <Link to={data.href}>
                                        <img className="ServiceImageService rounded-t-lg" src={data.img} alt="loremedia" />
                                    </Link>
                                    <div className="p-5">
                                        <Link to={data.href}>
                                            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">{data.title}</h5>
                                        </Link>
                                        <p className="mb-3 font-normal text-gray-800 dark:text-gray-700">{data.description}</p>

                                        <Link to={data.href} style={{ background: "#529512" }} className="service_button_more inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            En savoir plus
                                            <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                            </svg>
                                        </Link>
                                    </div>

                                    <div className='imageCover'></div>

                                </div>
                            </Link>



                        )
                    })}

                </div>

            </div>



        </div>
    )
}

export default Services