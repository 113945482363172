import React, { useState, useEffect } from 'react';
import './Slider.css';
import app from '../../../../../firebase/firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const Slider = () => {
  const [signaletiqueImgSrc, setsignaletiqueImgSrc] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore(app);
        const docRef = doc(db, 'portfolio', 'signaletique'); // Assuming 'developement' is the document ID
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setsignaletiqueImgSrc(data.imgSrc);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchData();

  }, []);

  return (
    <div className='relative'>
      <img className='imgSlider' src={signaletiqueImgSrc} alt='loremedia portfolio' />
      <div className='divTextDescPortfolio'>
        <div className='block relative'>
          <h1 className='titlePort'>PORTFOLIO</h1>
          <span className='borderBottomPort'></span>
          <p className='paragPort'>PANNEAUX DE SIGNALISATION</p>
        </div>
      </div>
    </div>
  );
}

export default Slider;
