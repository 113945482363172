import React from 'react'
import './Contact.css'
import { Link } from 'react-router-dom'

const Contact = () => {


    const myLocationOngoogleMaps = "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d571.28115380571!2d-6.829893243257262!3d34.01332812300085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s30%20Rue%20Moulay%20Ahmed%20Loukili%2C%2010090%20Rabat%2C%20Maroc%20est%20%C3%A0%20pr%C3%A9sent%20enregistr%C3%A9.!5e0!3m2!1sen!2sma!4v1713887433533!5m2!1sen!2sma"

    return (
        <div className='relative'>

            <div className='imgSectionAnimation'>


                <img className='animationOfBackground' src='/src/autre/anim1orange.png' alt='loremedia' />
                <img className='animationOfBackground2' src='/src/autre/anim1orange.png' alt='loremedia' />
                <img className='animationOfBackground3' src='/src/autre/anim1.png' alt='loremedia' />
                <img className='animationOfBackground4' src='/src/autre/anim1.png' alt='loremedia' />

            </div>

            <div className="w-full h-12">
                <svg viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 43.9999C106.667 43.9999 213.333 7.99994 320 7.99994C426.667 7.99994 533.333 43.9999 640 43.9999C746.667 43.9999 853.333 7.99994 960 7.99994C1066.67 7.99994 1173.33 43.9999 1280 43.9999C1386.67 43.9999 1440 19.0266 1440 9.01329V100H0V43.9999Z"
                        className="fill-current" style={{ color: "#529512" }}></path>
                </svg>
            </div>

            <section className="text-black ContactSection">

                <div style={{ marginTop: "4rem" }} className="container sectionContactDesktop py-12 mx-auto">
                    <div>
                        <div data-aos="fade-up" className="max-w-screen-md mb-8 lg:mb-16">
                            <h2 id='contactUS' style={{ color: "#1C1A50" }} className="ContactTitle text-2xl lg:text-5xl">CONTACTEZ-NOUS</h2>
                            <div className='borderTopTextContact'></div>
                        </div>

                    </div>

                    <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-3">
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-1">
                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <span style={{ color: "#529512", background: "#1c1a50", translate: "0px 6px" }} className="inline-block p-3 h-11 myInfoText rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </span>

                                <div data-aos="fade-right" style={{ display: "block" }}>
                                    <h2 className="myContactsTitles mt-4 text-base font-medium ">Email</h2>
                                    <Link to="mailto:contact@loremedia.ma"><p style={{ translate: "0px -5px" }} className=" text-sm myInfoText text-black">contact@loremedia.ma</p></Link>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <span className="inline-block p-3 myInfoText rounded-full" style={{ color: "#529512", background: "#1c1a50", translate: "0px 6px" }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                </span>

                                <div data-aos="fade-right" style={{ display: "block" }}>
                                    <h2 className="myContactsTitles mt-4 text-base font-medium ">Adresse</h2>
                                    <p style={{ translate: "0px -5px" }} className="mt-2 text-sm myInfoText text-black">Imm 30, Apt N8°, Rue Moulay Ahmed Loukili, Hassan - Rabat</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <span className="inline-block p-3 myInfoText rounded-full" style={{ color: "#529512", background: "#1c1a50", translate: "0px 6px" }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                    </svg>
                                </span>

                                <div data-aos="fade-right" style={{ display: "block" }}>
                                    <h2 className="myContactsTitles mt-4 text-base font-medium ">Telephone</h2>
                                    <Link to="tel:0654615707"><p style={{ translate: "0px -5px" }} className="mt-2 text-sm myInfoText text-black">+212 654615707</p></Link>
                                </div>
                            </div>

                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <span className="inline-block p-3 myInfoText rounded-full" style={{ color: "#529512", background: "#1c1a50", translate: "0px 6px" }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clock-3"><circle cx="12" cy="12" r="10" /><polyline points="12 6 12 12 16.5 12" /></svg>
                                </span>

                                <div data-aos="fade-right" style={{ display: "block" }}>
                                    <h2 className="myContactsTitles mt-4 text-base font-medium ">Horaires</h2>
                                    <p style={{ translate: "0px -5px" }} className="mt-2 text-sm myInfoText text-black">Lundi - Samedi : 9:00 - 18:30</p>
                                </div>
                            </div>
                        </div>

                        <div data-aos="fade-up" className="contactIferameMapDesktop overflow-hidden rounded-lg lg:col-span-2 h-96 lg:h-auto">
                            <iframe width="100%" height="100%" frameBorder="0" title="map" marginHeight="0" marginWidth="0" src={myLocationOngoogleMaps}></iframe>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Contact