import React from 'react';
import './Clients.css';

const Clients = () => {
    const images = [
        "/src/clients/Logo/360.png",
        "/src/clients/Logo/ancfcc.svg",
        "/src/clients/Logo/boukhrissa_ophtalmologue.svg",
        "/src/clients/Logo/centre_dentaire.svg",
        "/src/clients/Logo/digiassur.svg",
        "/src/clients/Logo/faculte.svg",
        "/src/clients/Logo/fondation.svg",
        "/src/clients/Logo/houkouki.svg",
        "/src/clients/Logo/Kinggeneration.svg",
        "/src/clients/Logo/mediazone.svg",
        "/src/clients/Logo/omran.svg",
        "/src/clients/Logo/Precieux.svg",
        "/src/clients/Logo/psyphone.svg",
        "/src/clients/Logo/Rabatchange.svg",
        "/src/clients/Logo/sopexa.svg",
        "/src/clients/Logo/tourisme.svg"
    ];

    const repeatedImages = images.concat(images);

    return (
        <div className='ClientsContainer'>
            <div id='reference' className='ClientsSousContainer'>
                <h1 style={{ color: "#1c1a50" }} data-aos="fade-up" data-aos-duration="1000" className="titleClients text-left text-4xl font-extrabold leading-none tracking-tight md:text-3xl lg:text-4xl">
                    <div className='borderTopTextClients'></div>
                    <span className='text-2xl lg:text-5xl serviceTitle'>ILS NOUS FONT CONFIANCE</span>
                </h1>

                <div className="carousel-container">
                    <div className="carousel">
                        {repeatedImages.map((image, index) => (
                            <img key={index} className="imgLogo" src={process.env.PUBLIC_URL + image} alt={`loremedia`} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clients;
