import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header/Header';
import LeftSide from '../../Header/LeftSide/LeftSide';
import '../../Dashboard.css';

import { collection, addDoc } from 'firebase/firestore'; // Update import for addDoc
import { firestore } from './firebase'; // Import firestore instance

const AddSliderHome = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [openFileInput, setOpenFileInput] = useState(false); // State for file input visibility
  const [formData, setFormData] = useState({
    imgSrc: '',
    imgTarget: 'sliderHome'
  });

  useEffect(() => {
    const mySession = localStorage.getItem(
      "sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)"
    );
    if (mySession !== 'true') {
      navigate("/anaadmin");
    }
  }, [navigate]);

  async function uploadImage(files) {
    try {
      const promises = files.map(async file => {
        const base64 = await readFileAsBase64(file);
        const formData = new FormData();
        formData.append('image', base64);
  
        const url = 'https://api.imgbb.com/1/upload?key=5380983aa1a38bbaef49a88ca25977d6';
        const response = await fetch(url, { method: 'POST', body: formData });
        const jsonData = await response.json();
        
        // Extracting image URL
        const imageUrl = jsonData.data.url;
  
        return imageUrl; // Return imageUrl directly
      });
  
      const imageUrls = await Promise.all(promises);
      console.log("Image URLs: ", imageUrls); // Log the image URLs
      return imageUrls; // Return array of image URLs
    } catch (error) {
      console.error('Error uploading image:', error);
      return []; // Return empty array in case of error
    }
  }
  

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
    });
  }

  function handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const newFiles = Array.from(files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    const previews = newFiles.map(file => URL.createObjectURL(file));
    setImagePreviews(prevPreviews => [...prevPreviews, ...previews]);
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    const newFiles = Array.from(files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    const previews = newFiles.map(file => URL.createObjectURL(file));
    setImagePreviews(prevPreviews => [...prevPreviews, ...previews]);
  }

  async function handleClickUpload() {
    if (selectedFiles.length > 0) {
      const uploadedImageURLs = await uploadImage(selectedFiles);
      console.log("Uploaded Image URLs: ", uploadedImageURLs);
      
      // Assuming uploadedImageURLs and selectedFiles are of same length
      for (let i = 0; i < uploadedImageURLs.length; i++) {
        const imageURL = uploadedImageURLs[i];
        const file = selectedFiles[i];
        
        AddSliderHome(imageURL, formData.imgDesc); // Pass imageURL, sliderTarget, and image description to AddSliderHome
      }
  
      setSelectedFiles([]);
      setImagePreviews([]);
    }
  }
  

  function handleBrowseClick() {
    setOpenFileInput(true);
  }

  function handleFileInputClick(e) {
    if (openFileInput) {
      e.stopPropagation();
    }
  }

  function handleImageClick(index) {
    setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
  }


  function handleDescChange(e) {
    setFormData(prevData => ({
      ...prevData,
      imgDesc: e.target.value,
    }));
  }

  const AddSliderHome = async (imageURL) => {
    try {
      if (!imageURL) {
        console.error('Image URL is missing');
        alert("image cant upload (slow cnx! or image extention not exist)")
        return;
      }
  
      const docRef = await addDoc(collection(firestore, 'homePage'), {
        imgSrc: imageURL,
        imgTarget: 'sliderHome'
      });
  
      console.log('Document added successfully with ID: ', docRef.id);
      alert("image added successfuly!")
      setFormData({
        imgSrc: '',
        imgTarget: 'sliderHome'
      });
    } catch (error) {
      console.error('Error adding document:', error);
    }
  };
  

  return (
    <div className='Dashboard'>
      <Header />
      <LeftSide />
      <div className='HomeDashboard'>
        <div
          className="flex items-center justify-center mt-8 dropzone"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className="mx-auto w-full max-w-[550px] bg-white">
            <div className="py-4 px-9">
              <div className="mb-6 pt-4">
                <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                  Add Slider Home
                </label>
                <div className="mb-8">
                  <input
                    ref={fileInputRef}
                    id="dropzone-file"
                    type="file"
                    accept="image/*"
                    className="sr-only"
                    onChange={handleFileInputChange}
                    onClick={handleFileInputClick}
                    multiple
                  />
                  <label
                    htmlFor="dropzone-file"
                    className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center cursor-pointer"
                    onClick={handleBrowseClick}
                  >
                    <div>
                      <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                        Drop files here
                      </span>
                      <span className="mb-2 block text-base font-medium text-[#6B7280]">
                        Or
                      </span>
                      <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                        Browse
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              <div className='flex gap-8'>
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="mb-4 flex">
                    <img
                      src={preview}
                      alt={`Selected ${index + 1}`}
                      className="imagePreview w-20 h-auto cursor-pointer hover:bg-red-200"
                      onClick={() => handleImageClick(index)}
                    />
                  </div>
                ))}
              </div>

              <div>
                <button
                  className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none"
                  onClick={handleClickUpload}
                >
                  Send File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSliderHome;
