import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAfEZHERfXKDxBqRwEvOvSg_eP3MsCvSGo",
  authDomain: "loremedia-db.firebaseapp.com",
  projectId: "loremedia-db",
  storageBucket: "loremedia-db.appspot.com",
  messagingSenderId: "321026236881",
  appId: "1:321026236881:web:378330fed081503c7cc0f0",
  measurementId: "G-3EK7Z5NHM8"
};


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export { firestore };
