import React, { useEffect } from 'react';
import './Communicatin.css';
import 'animate.css';

const Marketing = () => {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      })


    return (
        <div className='Communication'>
            <img className='ComSlider' src='/src/autre/marketing.png' alt='loremedia' />

            <div className='ComSection'>
                <div className='Comdiv1'>
                    <p className='full_com_text'>
                        <h2 style={{ color: "#1C1A50" }} className="animate__animated animate__bounceInLeft comTitle my-6 text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl">
                            BIEN CERNER VOS ASPIRATIONS
                        </h2>
                        <div className='animate__animated animate__bounceInLeft theGreenLine'></div>

                        <p className='animate__animated animate__bounceInLeft com_paragraph_1'>
                            Vous lancez votre entreprise et vous manquez de visibilité en ligne ? Vous cherchez à donner un coup de frais à votre identité visuelle ? Vous voulez moderniser votre présence sur le web ?
                        </p>

                        <h3 className='animate__animated animate__bounceInLeft mt-2 com_paragraph_2'>
                            NOUS AVONS LA SOLUTION À TOUTES VOS INTERROGATIONS AVEC DES STRATÉGIES SUR MESURE.
                        </h3>

                        <p className='animate__animated animate__bounceInLeft com_paragraph_3 mt-2'>
                            Notre équipe de spécialistes est là pour vous guider avec les meilleures recommandations.


                        </p>

                        <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
                            Pour ce faire, nous devons appréhender pleinement votre projet et sa structure : ses points forts, ses lacunes et ses objectifs.
                        </p>

                        <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-2'>
                            Notre objectif principal est d'interagir avec vous afin de concevoir des solutions personnalisées qui s'alignent sur vos besoins, vos exigences et vos désirs.                            <br />
                            <span className='animate__animated animate__bounceInLeft com_paragraph_5_green'>
                                Nous nous attachons à proposer des réponses en adéquation avec vos valeurs et l'image de marque de votre entreprise.                            </span>
                        </p>

                        <h2 style={{ color: "#1C1A50" }} className="animate__animated animate__bounceInLeft comTitle my-6 text-2xl tracking-tight font-extrabold sm:text-3xl md:text-4xl">
                            NOTRE APPROCHE

                        </h2>

                        <p className='animate__animated animate__bounceInLeft com_paragraph_4 mt-1'>
                            Mieux vous comprendre, c'est mieux saisir vos attentes et vos aspirations. Alors, discutons-en lors d'un rendez-vous !
                        </p>
                    </p>
                </div>
                <img className="animate__animated animate__bounceInRight imgSection" src="/src/autre/marketingSquare.jpg" alt="" />
            </div>

            


        </div>
    );
}

export default Marketing;
