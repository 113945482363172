import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './output.css';
import Home from './Components/Home/Home';
import Nav from './Components/Header/Nav';
import Footer from './Components/Footer/Footer';
import Portfolio from './Components/Portfolio/Portfolio';
import Graphic from './Components/Portfolio/Components/Graphic/Graphic';
import Devlopement from './Components/Portfolio/Components/Devlopement/Devlopement';
import Stands from './Components/Portfolio/Components/Stands/Stands';
import Signaletique from './Components/Portfolio/Components/Signalétique/Signaletique';
import Renovation from './Components/Portfolio/Components/Renovation/Renovation';
import Admin from './Admin/Admin/Admin';
import Dashboard from './Admin/Dashboard/Dashboard';
import AddSlider from './Admin/Dashboard/Preview/Pages/AddSlider';
import AddService from './Admin/Dashboard/Preview/Pages/AddService';
import DeleteService from './Admin/Dashboard/Preview/Pages/DeleteService';
import UpdateService from './Admin/Dashboard/Preview/Pages/UpdateService';
import UpService from './Admin/Dashboard/Preview/Pages/UpService';
import RedirectSEO from './Test/RedirectSEO';
import Communication from './Components/Home/Communication/Communication';
import AddSliderHome from './Admin/Dashboard/Preview/Pages/AddSliderHome';
import DeleteSliderHome from './Admin/Dashboard/Preview/Pages/DeleteSliderHome';
import Faq from './Components/Footer/Pages/Faq';
import Politique from './Components/Footer/Pages/Politique';
import Marketing from './Components/Home/Marketing/Marketing';
import MotionGraphic from './Components/Portfolio/Components/MotionGraphic/MotionGraphic';
import TravauxImpr from './Components/Home/Travaux-Impr/TravauxImpr';
import Seo_Communication from './Test/Seo_Communication';
import { useEffect } from 'react';
import Deve_Rabat from './Components/Portfolio/Components/Developemt_Rabat/Deve_Rabat';


function App() {

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent default right-click behavior
    };

    // Add event listener when component mounts
    document.addEventListener('contextmenu', handleContextMenu);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();
  const isAnaadminRoute = location.pathname.startsWith('/anaadmin');

  // Conditional rendering of Nav and Footer based on the route
  const nav = !isAnaadminRoute && <Nav />;
  const footer = !isAnaadminRoute && <Footer />;

  return (
    <div className="App">
      {nav}
      <Routes>

        {/* Home */}


        <Route path='/' element={<Home />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/portfolio/creation-graphique' element={<Graphic />} />
        <Route path='/portfolio/creation-site-web' element={<Devlopement />} />
        <Route path='/portfolio/stands' element={<Stands />} />
        <Route path='/portfolio/signalétique' element={<Signaletique />} />
        <Route path='/portfolio/renovation-et-Amenagement' element={<Renovation />} />
        <Route path='/portfolio/motion-graphic' element={<MotionGraphic />} />
        <Route path='/Communication' element={<Communication />} />
        <Route path="/travaux-d'impression" element={<TravauxImpr />} />
        <Route path='/marketing-digital' element={<Marketing />} />



        <Route path='/faq' element={<Faq />} />
        <Route path='/politique' element={<Politique />} />



        {/* Admin */}


        <Route path='/anaadmin' element={<Admin />} />
        <Route path='/anaadmin/dashboard' element={<Dashboard />} />


        {/* Dashboard */}


        <Route path='/anaadmin/dashboard/addSLider' element={<AddSlider />} />

        <Route path='/anaadmin/dashboard/addService' element={<AddService />} />
        <Route path='/anaadmin/dashboard/deleteService' element={<DeleteService />} />
        <Route path='/anaadmin/dashboard/updateService' element={<UpdateService />} />
        <Route path='/anaadmin/dashboard/upService/:id' element={<UpService />} />

        <Route path='/anaadmin/dashboard/addSLider_home' element={<AddSliderHome />} />
        <Route path='/anaadmin/dashboard/deleteSLider_home' element={<DeleteSliderHome/>} />

        
        
        {/* Seo */}

        <Route path='/developpement-web' element={<RedirectSEO />} />
        <Route path='/graphisme' element={<RedirectSEO />} />
        <Route path='/creation-siteweb-rabat' element={<Deve_Rabat />} />

        <Route path='/agence-de-communication-rabat' element={<Seo_Communication />} />


        <Route path='/:id' element={<Home />} />




      </Routes>
      {footer}
    </div>
  );
}

export default App;
