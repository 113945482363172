import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../Header/Header';
import LeftSide from '../../Header/LeftSide/LeftSide';
import { doc, getDoc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { firestore } from './firebase';

const UpService = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [serviceData, setServiceData] = useState({
        imgSrc: '',
        imgTag: '',
        imgDesc: '',
    });
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const mySession = localStorage.getItem("sesion_true_KLKas4874asasdkljs565dKJKLJ3asdkljas_asdlsad_+=(loremedia.ma)");
        if (mySession !== 'true') {
            navigate("/anaadmin");
        }
    }, [navigate]);

    useEffect(() => {
        async function fetchServiceData() {
            try {
                const docRef = doc(firestore, 'portfolioServices', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setServiceData(data);
                    setSelectedCategory(data.imgTag); // Set selected category based on imgTag
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching service data:', error);
            }
        }

        fetchServiceData();
    }, [id]);

    async function uploadImage(files) {
        try {
            const promises = files.map(async file => {
                const base64 = await readFileAsBase64(file);
                const formData = new FormData();
                formData.append('image', base64);
      
                const url = 'https://api.imgbb.com/1/upload?key=5380983aa1a38bbaef49a88ca25977d6';
                const response = await fetch(url, { method: 'POST', body: formData });
                const jsonData = await response.json();
                
                // Extracting image URL
                const imageUrl = jsonData.data.url;
      
                return imageUrl; // Return imageUrl directly
            });
      
            const imageUrls = await Promise.all(promises);
            console.log("Image URLs: ", imageUrls); // Log the image URLs
            return imageUrls; // Return array of image URLs
        } catch (error) {
            console.error('Error uploading image:', error);
            return []; // Return empty array in case of error
        }
    }
    
    function readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            let imageUrl = serviceData.imgSrc; // Default to the existing image URL
    
            if (selectedFiles.length > 0) {
                const uploadedImageURLs = await uploadImage(selectedFiles);
                console.log("Uploaded Image URLs: ", uploadedImageURLs);
                
                if (uploadedImageURLs.length > 0) {
                    imageUrl = uploadedImageURLs[0]; // Use the uploaded image URL
                } else {
                    console.error('No image uploaded!');
                }
            }
    
            const docRef = doc(firestore, 'portfolioServices', id);
            await updateDoc(docRef, {
                imgSrc: imageUrl,
                imgTag: selectedCategory,
                imgDesc: serviceData.imgDesc,
            });
            console.log('Document updated successfully!');
            alert('Service updated successfully!');
            navigate("/anaadmin/dashboard/updateService");
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }
    

    function handleFileInputChange(e) {
        const files = e.target.files;
        const newFiles = Array.from(files);
        setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    }

    function handleSelectChange(e) {
        setSelectedCategory(e.target.value); // Update the selected category state
    }

    return (
        <div className='Dashboard'>
            <Header />
            <LeftSide />
            <div className='HomeDashboard p-2'>
                <img style={{width:"15%", zIndex:"-1"}} className='myUpdateImage m-auto p-0 absolute' src={serviceData.imgSrc} alt='test' />
                <form className="bg-white myUpdateForm shadow-xl border rounded px-8 pt-6 pb-8 mb-4 w-3/4 m-auto mt-12" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">image Tag:</label>
                        <input
                            type="text"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline"
                            value={serviceData.imgTag}
                            readOnly disabled
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Description:</label>
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={serviceData.imgDesc}
                            onChange={e => setServiceData(prevState => ({ ...prevState, imgDesc: e.target.value }))}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Category:</label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedCategory}
                            onChange={handleSelectChange}
                        >
                            <option value="design">Création graphique</option>
                            <option value="developement">Création Site Web</option>
                            <option value="stands">Stands</option>
                            <option value="signaletique">Signalétique</option>
                            <option value="renovation">Rénovation et Aménagement</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <input
                            ref={fileInputRef}
                            id="upload-image"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileInputChange}
                        />
                        <label
                            htmlFor="upload-image"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                        >
                            Upload Image
                        </label>
                        {selectedFiles.length > 0 && (
                            <span className="text-gray-500 ml-2">{selectedFiles.length} image(s) selected</span>
                        )}
                    </div>
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Update Service
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UpService;